import React from "react"
import { useSiteMetadata } from "../hooks/use-site-metadata"
// import { useLocation } from "@reach/router"

export default function Seo({ pageSeo, children }) {
  // const { pathname } = useLocation()
  const { title: defaultTitle, titleTemplate, author, description: defaultDescription, siteUrl, image, twitterUsername } = useSiteMetadata()

  const compiledSeo = {
    title: (pageSeo && pageSeo.title) || defaultTitle,
    titleTemplate,
    author,
    description: (pageSeo && pageSeo.description) || defaultDescription,
    url: `${siteUrl}${(pageSeo && pageSeo.pathname) || ''}`,
    image: `${siteUrl}${(pageSeo && pageSeo.image) || image}`,
    twitterUsername,
  }

  return (
    <>
      <title>{`${compiledSeo.title}${pageSeo && pageSeo.pathname === "/" ? "" : titleTemplate}`}</title>
      <meta name="description" content={compiledSeo.description} />
      <meta name="image" content={compiledSeo.image} />
      <link rel="apple-touch-icon" sizes="180x180" href="/favicon/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon/favicon-16x16.png" />
      <link rel="manifest" href="/favicon/site.webmanifest" />
      <link rel="mask-icon" href="/favicon/safari-pinned-tab.svg" color="#5bbad5" />
      <meta name="msapplication-TileColor" content="#00aba9" />
      <meta name="theme-color" content="#800080" />
      <link rel="canonical" href={compiledSeo.url} />
      <meta property="og:type" content={compiledSeo.post ? "article" : "website"} />
      <meta property="og:url" content={compiledSeo.url} />
      <meta property="og:title" content={compiledSeo.title} />
      <meta property="og:description" content={compiledSeo.description} />
      <meta property="og:image" content={compiledSeo.image} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content={twitterUsername} />
      <meta name="twitter:creator" content={twitterUsername} />
      <meta name="twitter:title" content={compiledSeo.title} />
      <meta name="twitter:description" content={compiledSeo.description} />
      <meta name="twitter:image" content={compiledSeo.image} />
      <meta name="twitter:image:src" content={compiledSeo.image} />
      <meta name="twitter:image:alt" content="Main image of the page." />
      {children}
    </>
  )
}